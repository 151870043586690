import React, { useState, useEffect } from 'react';

import DocumentationContent from '../../components/DocumentationContent';
import DocumentationSidebar from '../../components/DocumentationSidebar';
import Footer from "../../components/Footer";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import ReactMarkdown from 'react-markdown'
import Link from "gatsby-link";

function ChangelogPage() {
    const [changelogMarkdown, setChangelogMarkdown] = useState("");

    useEffect(() => {
        try {
            fetch('https://raw.githubusercontent.com/silvia-odwyer/lightbox.js/master/CHANGELOG.md') 
            .then(response => response.text())
            .then(result => {
                console.log("resulting markdown ", result);
                 setChangelogMarkdown(result);
            }); 
        }
        catch(error) {
            console.log("error ", error)
        }   
    })

  // const [sidebarNavOpen, setSidebarNavOpen] = useState(false);
  // const [sidebarLinkOpen, setSidebarLinkOpen] = useState(true);
  // const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  // {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  // {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  // {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  // {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  // {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  // {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];

  // const images2 = [{title: "Photon", src: image}];
  return (      
    <Layout>
    <Header overrideDarkTheme={true}/>

    <div>
          <main className="flex-grow">

          <section>
            <div className="max-w-7xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                {/* Main content */}
                <div className="md:flex md:justify-between" data-sticky-container>

                  <DocumentationSidebar />

                  <div className="md:flex-grow font-inter">

        {/* <Header /> */}
        <div className="text-lg text-gray-600 pt-8 prose lg:prose-lg lg:prose-h1:text-4xl
        lg:prose-h2:text-2xl lg:prose-h3:text-xl">


        <ReactMarkdown>
            {changelogMarkdown}
        </ReactMarkdown> 
    

</div>


</div>

                </div>

              </div>
            </div>
          </section>

          </main>
        <Footer />

    </div>

    </Layout>
  



  );
}

export default ChangelogPage;